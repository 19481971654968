import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilAlbum,
  cilAudioSpectrum,
  cilPlaylistAdd,
  cilChartLine,
  cilChart,
  cilDollar,
  cilSettings,
  cilBook,
  cilAccountLogout,
  cilCloudDownload,
  cilChatBubble
} from '@coreui/icons'

export const iconsSet = Object.assign({}, {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilAlbum,
  cilAudioSpectrum,
  cilPlaylistAdd,
  cilChartLine,
  cilChart,
  cilDollar,
  cilSettings,
  cilBook,
  cilAccountLogout,
  cilCloudDownload,
  cilChatBubble
})
