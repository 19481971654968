<template>
  <router-view v-if="$root.$data.portalInfo !== null"></router-view>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="scss">
  @import '@/assets/scss/style';
</style>
