import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    component: () => import('@/containers/DefaultContainer.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'royalty-accounting',
        name: 'royalty-accounting',
        props: true,
        component: () => import('@/views/RoyaltyAccounting.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('@/views/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'open active',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line
router.beforeEach((to: any, from, next) => {
  if (router.app.$data !== undefined) {
    router.app.$data.crumbs = []
  }

  if ((!['login', 'password-reset'].includes(to.name)) && ((router.app.$data === undefined) || (router.app.$data.me === null))) {
    next({
      name: 'login',
      params: { nextUrl: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
