import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// define your typings for the store state
export interface State {
  [index: number]: string | boolean;
  sidebarShow: string | boolean;
  sidebarMinimize: boolean;
  darkMode: boolean;
  asideShow: boolean;
}

export default new Vuex.Store({
  state: {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    darkMode: true,
    asideShow: false
  },
  mutations: {
    toggleSidebarDesktop (state: State) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state: State) {
      // const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      // state.sidebarShow = sidebarClosed ? true : 'responsive' 
      state.sidebarShow = true
    },
    set (state: State, [variable, value]) {
      state[variable] = value
    },
    toggle (state: State, variable) {
      state[variable] = !state[variable]
    }
  },
  actions: {
  },
  modules: {
  }
})
