import Vue, { ComponentOptions } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVuePro from '@coreui/vue-pro/src/index.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { iconsSet as icons } from './assets/icons/icons'
import axios, { AxiosStatic, AxiosResponse } from 'axios'
import Notifications from 'vue-notification'

Vue.config.productionTip = false

// Install CoreUi
Vue.use(CoreuiVuePro)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Notifications
Vue.use(Notifications)

// Axios
{
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT
  })

  Vue.prototype.$http = instance
}

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    // eslint-disable-next-line
    icons?: any;
  }
}

new Vue({
  router,
  store,
  icons, 
  render: h => h(App),
  data: {
    portalInfo: null,
  },
  created () {
    this.$http
      .get('portal/info', { params: { hostname: location.hostname } })
      .then((response: AxiosResponse) => {
        this.portalInfo = response.data
      })
  },
}).$mount('#app')
